import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'
import DefaultLayout from '../../layout/DefaultLayout'
import { getMe } from '../../MeProvider.ts'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../AuthProvider.ts'

const Account = () => {
	const me = getMe()
	const { authUser } = useAuth()
	const [firstName, setFirstName] = useState<string>('')
	const [lastName, setLastName] = useState<string>('')

	console.log(me)

	useEffect(() => {
		setFirstName(me.firstName ?? '')
		setLastName(me.lastName ?? '')
	}, [me])

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		console.log('update user')
	}

	return (
		<DefaultLayout>
			<Breadcrumb pageName="Account" />

			<div className="grid grid-cols-12 gap-8">
				<div className="rounded-xl bg-white shadow-default dark:border-strokedark dark:bg-boxdark col-span-12 xl:col-span-8 p-7 flex flex-col justify-between">
					<div className="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
						<div className="w-full sm:w-1/2">
							<label className="mb-3 block text-sm font-medium text-black dark:text-white" htmlFor="firstName">
								First Name
							</label>
							<div className="relative">
								<span className="absolute left-4.5 top-4">
									<svg className="fill-current" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g opacity="0.8">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M3.72039 12.887C4.50179 12.1056 5.5616 11.6666 6.66667 11.6666H13.3333C14.4384 11.6666 15.4982 12.1056 16.2796 12.887C17.061 13.6684 17.5 14.7282 17.5 15.8333V17.5C17.5 17.9602 17.1269 18.3333 16.6667 18.3333C16.2064 18.3333 15.8333 17.9602 15.8333 17.5V15.8333C15.8333 15.1703 15.5699 14.5344 15.1011 14.0655C14.6323 13.5967 13.9964 13.3333 13.3333 13.3333H6.66667C6.00363 13.3333 5.36774 13.5967 4.8989 14.0655C4.43006 14.5344 4.16667 15.1703 4.16667 15.8333V17.5C4.16667 17.9602 3.79357 18.3333 3.33333 18.3333C2.8731 18.3333 2.5 17.9602 2.5 17.5V15.8333C2.5 14.7282 2.93899 13.6684 3.72039 12.887Z"
												fill=""
											></path>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M9.99967 3.33329C8.61896 3.33329 7.49967 4.45258 7.49967 5.83329C7.49967 7.214 8.61896 8.33329 9.99967 8.33329C11.3804 8.33329 12.4997 7.214 12.4997 5.83329C12.4997 4.45258 11.3804 3.33329 9.99967 3.33329ZM5.83301 5.83329C5.83301 3.53211 7.69849 1.66663 9.99967 1.66663C12.3009 1.66663 14.1663 3.53211 14.1663 5.83329C14.1663 8.13448 12.3009 9.99996 9.99967 9.99996C7.69849 9.99996 5.83301 8.13448 5.83301 5.83329Z"
												fill=""
											></path>
										</g>
									</svg>
								</span>
								<input
									className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
									type="text"
									name="firstName"
									id="firstName"
									placeholder={'Bruce'}
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
								/>
							</div>
						</div>
						<div className="w-full sm:w-1/2">
							<label className="mb-3 block text-sm font-medium text-black dark:text-white" htmlFor="lastName">
								Last name
							</label>
							<input
								className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
								type="text"
								name="lastName"
								id="lastName"
								placeholder={'Wayne'}
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
							/>
						</div>
					</div>

					<div className="flex justify-end gap-4.5 pt-12">
						<button className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white">
							Change password
						</button>
						<button
							className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90"
							onClick={handleSubmit}
						>
							Save
						</button>
					</div>
				</div>
				<div className="rounded-xl bg-white shadow-default dark:border-strokedark dark:bg-boxdark col-span-12 xl:col-span-4 p-4 flex flex-col items-center">
					<div className="relative z-30 mx-auto h-20 w-full max-w-20 bg-white/20 overflow-hidden flex justify-center items-center rounded-full mt-7">
						{authUser?.picture && <img src={authUser.picture} alt="profile" />}
						{!authUser?.picture && (
							<img
								src="https://static.vecteezy.com/system/resources/thumbnails/003/337/584/small/default-avatar-photo-placeholder-profile-icon-vector.jpg"
								alt="profile"
							/>
						)}
					</div>
					<h5 className="text-2xl mb-2 mt-4 font-semibold text-black">{me.userPosition?.position.title}</h5>
					<p className="text-xl mb-2">{me.organizations[0].name}</p>
				</div>
			</div>
		</DefaultLayout>
	)
}

export default Account
