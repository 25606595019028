interface EmptyRowProps {
	emptyText: string
}

const EmptyTableRow = ({ emptyText }: EmptyRowProps) => {
	return (
		<tr>
			<td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11" colSpan={100}>
				<h5 className="font-medium text-black dark:text-white text-center">{emptyText}</h5>
			</td>
		</tr>
	)
}

export default EmptyTableRow
