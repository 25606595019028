import { ModalMap, ModalMeta } from './ModalProvider.tsx'

export type ModalAction =
	| { type: 'MODAL_OPEN'; payload: { modalFileName: string; meta: ModalMeta } }
	| { type: 'MODAL_CLOSE'; payload: { modalFileName: string } }

interface UIState {
	modal: ModalMap
}

export const initialState: UIState = {
	modal: {},
}

export function modalReducer(state: UIState, action: ModalAction): UIState {
	switch (action.type) {
		case 'MODAL_OPEN': {
			const id = action.payload.modalFileName
			const meta = action.payload.meta
			return {
				...state,
				modal: {
					...state.modal,
					[id]: { id, meta, open: true },
				},
			}
		}

		case 'MODAL_CLOSE': {
			const id = action.payload.modalFileName
			return {
				...state,
				modal: {
					...state.modal,
					[id]: { id, open: false },
				},
			}
		}

		default:
			return state
	}
}
