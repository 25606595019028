import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'

export const updateUserOrganizationRoles = async (client: HttpClient, userId: string, roleIds: string[]) => {
	const response = await client.put(`/api/users/${userId}/roles`, {
		roleIds,
	})

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
