import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'

// {
//     "id": "19a3f5ef-bff1-4338-b229-60094e8594d8",
//     "title": "Principal Software Engineer",
//     "level": "IC6",
//     "role": "Software Engineer",
//     "description": "I deliver results against a strategic company-level business objective or key component of the EPD mission",
//     "organizationId": "85280b79-100c-45ff-8e88-787cd7bd1e8f",
//     "questions": [
//         {
//             "id": "59c54a9e-53a2-4bfe-a813-7f82630974b4",
//             "text": "I personify {organization_name}’s core values and support our community.",
//             "name": "Craft",
//             "subName": "Culture Leader"
//         },
//     ]

export interface IQuestion {
	id: string
	text: string
	name: string
	subName: string
}

export interface IPosition {
	id: string
	title: string
	level: string
	role: string
	description: string
	organizationId: string
	questions: IQuestion[]
}

export const getPosition = async (client: HttpClient, positionId: string): Promise<IPosition> => {
	const response = await client.get<IPosition>(`/api/positions/${positionId}`)

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
