interface CardProps {
	padding?: boolean
	children: React.ReactNode | React.ReactNode[]
}

const Card = ({ children, padding = true }: CardProps) => {
	return (
		<div
			className={`rounded-2xl bg-white shadow-default dark:border-strokedark dark:bg-boxdark my-3 overflow-hidden ${padding ? 'px-5 py-6 sm:px-7.5' : ''}`}
		>
			{children}
		</div>
	)
}

export default Card
