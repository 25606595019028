import { createContext, useContext } from 'react'

interface OrgAuth {
	token?: string
	authOrgUser?: any
	handleOrganizationChange?: (org: any) => Promise<void>
	userOrganizations: any[]
}

const OrgAuthContext = createContext<OrgAuth>({ userOrganizations: [] })

const useOrgAuth = (): OrgAuth => {
	return useContext(OrgAuthContext)
}

export { OrgAuthContext, useOrgAuth }
