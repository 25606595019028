let NEXTPEAK_API_URL: string

if (window.location.hostname === 'localhost') {
	NEXTPEAK_API_URL = 'http://localhost:8080'
	// GRAPHQL_API_URL = 'https://api.nextpeak.work'
} else {
	NEXTPEAK_API_URL = 'https://api.nextpeak.work'
}

export { NEXTPEAK_API_URL }
