import { useMemo } from 'react'
import { NEXTPEAK_API_URL } from './config'
import { NextpeakClient } from './services/nextpeakClient'
import { HttpClientFactory } from './services/httpClient'

export interface IRestClientOpts {
	withoutToken?: boolean
}

export const useRestClient = ({ withoutToken }: IRestClientOpts = {}) => {
	const clientToken = window.localStorage.getItem('accessToken')
	const orgToken = window.localStorage.getItem('organizationToken')
	return useMemo(() => {
		const apiToken = orgToken ?? clientToken ?? undefined

		if (!apiToken && !withoutToken) {
			throw new Error('No token found')
		}

		return new NextpeakClient({ baseUrl: NEXTPEAK_API_URL, apiToken: !withoutToken ? apiToken : undefined }, new HttpClientFactory())
	}, [orgToken, clientToken])
}
