interface LabelProps {
	children: React.ReactNode
	type?: 'success' | 'danger' | 'warning' | 'info'
}

const getLabelTypeClass = (type: 'success' | 'danger' | 'warning' | 'info') => {
	switch (type) {
		case 'success':
			return 'bg-success text-success'
		case 'danger':
			return 'bg-danger text-danger'
		case 'warning':
			return 'bg-warning text-warning'
		case 'info':
			return 'bg-primary text-primary'
	}
}

/**
 * Label component - only supporting rounded style and 4 color types for now
 * @param children
 * @param type
 * @constructor
 */
const Label = ({ children, type }: LabelProps) => {
	const className = `inline-flex rounded-full bg-opacity-10 px-3 py-1 text-sm font-medium ${getLabelTypeClass(type ?? 'info')}`

	return <p className={className}>{children}</p>
}

export default Label
