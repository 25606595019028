import { useCallback, useEffect, useState } from 'react'
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom'
import PageTitle from './components/PageTitle'
import SignIn from './pages/Authentication/SignIn'
import { AuthContext } from './AuthProvider.ts'
import { type IdTokenClaims, useHandleSignInCallback, useLogto } from '@logto/react'
import * as Sentry from '@sentry/react'
import OrganizationAuth from './OrganizationAuth.tsx'
import { useOrganizationsByToken } from './hooks/Logto.tsx'
import FullPageLoader from './common/FullPageLoader'
import ConfirmInvite from './pages/Authentication/ConfirmInvite.tsx'

/**
 * Any authentication business logic related to external auth service should be handled here.
 * @constructor
 */
function Authenticator() {
	const [, setSearchParams] = useSearchParams()

	const { isLoading } = useHandleSignInCallback(() => {
		const redirectUrl = localStorage.getItem('postLoginRedirect')
		if (redirectUrl) {
			setSearchParams({ redirect: redirectUrl })
		} else {
			setSearchParams({})
		}
	})

	const { isAuthenticated, getAccessToken, getIdTokenClaims, getOrganizationTokenClaims, getOrganizationToken } = useLogto()
	const [user, setUser] = useState<IdTokenClaims>()
	const [token, setToken] = useState<string>()
	const [userOrganizations, setUserOrganizations] = useState<any[]>([])
	const [activeOrganizationAuth, setActiveOrganizationAuth] = useState<any>()
	const [organizationToken, setOrganizationToken] = useState<string>()

	const { data, isLoading: isOrganizationsLoading } = useOrganizationsByToken(token)

	const handleToken = useCallback(async () => {
		if (isAuthenticated) {
			const claims = await getIdTokenClaims()
			setUser(claims)
			const accessToken = await getAccessToken(import.meta.env.VITE_LOGTO_API_URL)
			if (accessToken && accessToken !== token) {
				setToken(accessToken)
				localStorage.setItem('accessToken', accessToken)
			}
		}
	}, [isAuthenticated, getIdTokenClaims, getAccessToken, token])

	const handleOrganizationChange = useCallback(
		async (org: any) => {
			const organizationClaims = await getOrganizationTokenClaims(org.id)
			setActiveOrganizationAuth({ ...organizationClaims, orgId: org.id, internalOrgId: org.internalId, orgName: org.name })
			const orgToken = await getOrganizationToken(org.id)
			if (orgToken) {
				setOrganizationToken(orgToken)
				localStorage.setItem('organizationToken', orgToken)
			}
		},
		[getOrganizationToken, setOrganizationToken]
	)

	useEffect(() => {
		handleToken()
	}, [isAuthenticated, getIdTokenClaims, getAccessToken, token, handleToken])

	useEffect(() => {
		if (data) {
			setUserOrganizations(data)
			// TODO: This is a temporary solution to handle single organization users
			if (data.length === 1) {
				handleOrganizationChange(data[0])
			}
		}
	}, [data])

	const { pathname } = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	if (isLoading || isOrganizationsLoading) {
		return <FullPageLoader />
	}

	if (!isAuthenticated || !token || !user) {
		return (
			<Routes>
				<Route
					index
					element={
						<>
							<PageTitle title="Signin | NextPeak" />
							<SignIn />
						</>
					}
				/>
				<Route
					path="/invites/:inviteId/confirm"
					element={
						<>
							<PageTitle title="Invite | NextPeak" />
							<ConfirmInvite />
						</>
					}
				/>
			</Routes>
		)
	}

	return (
		<AuthContext.Provider value={{ token, authUser: user, refreshAuth: handleToken }}>
			<OrganizationAuth
				handleOrganizationChange={handleOrganizationChange}
				activeOrganizationAuth={activeOrganizationAuth}
				organizationToken={organizationToken}
				userOrganizations={userOrganizations}
			/>
		</AuthContext.Provider>
	)
}

//export default App
export default Sentry.withProfiler(Authenticator)
