import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'
import DefaultLayout from '../../layout/DefaultLayout'
import { useParams } from 'react-router-dom'
import { useGraphQLClient } from '../../useGraphQLClient.ts'
import { Assessment, AssessmentQuery, useAssessmentQuery, useAssessmentWithResponsesQuery } from '../../generated/graphql.ts'
import 'survey-core/defaultV2.min.css'
import Loader from '../../common/Loader/index.tsx'
import { isAssessmentFilledOutByManager, isAssessmentFilledOutByUser } from '../../utils.ts'
import { mergeResponses } from './utils.ts'
import Card from '../../components/Card.tsx'
import { generateChartData } from '../Home/utils.ts'
import { RadarComponent } from '../../components/Charts/Radar.tsx'

const AnswersTable = ({ answers }: { answers: any[] }) => {
	return (
		<div className="max-w-full overflow-x-auto">
			<table className="w-full table-auto">
				<thead>
					<tr className="bg-gray-2 text-left dark:bg-meta-4">
						<th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white">Question</th>
						<th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">Manager</th>
						<th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">Employee</th>
					</tr>
				</thead>
				<tbody>
					{answers.map((answer) => (
						<tr key={answer.id} className="border-b border-stroke dark:border-strokedark">
							<td className="py-4 px-4 text-black dark:text-white">{answer.question.text}</td>
							<td className="py-4 px-4 text-black dark:text-white">{answer.managerAnswerValue}</td>
							<td className="py-4 px-4 text-black dark:text-white">{answer.employeeAnswerValue}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

interface ChartsProps {
	employeeResponse: any
	managerResponse: any
	assessmentId: string
	assessment: AssessmentQuery['assessment']
}

const Charts = ({ employeeResponse, managerResponse, assessmentId, assessment }: ChartsProps) => {
	const graphQlClient = useGraphQLClient()
	const { data } = useAssessmentWithResponsesQuery(graphQlClient, { assessmentId })

	if (!data) {
		return <Loader />
	}

	const mappedManagerResponse = data.assessment.assessmentResponses.find((response: any) => response.id === managerResponse.id)
	const mappedEmployeeResponse = data.assessment.assessmentResponses.find((response: any) => response.id === employeeResponse.id)

	const result = mergeResponses(mappedEmployeeResponse, mappedManagerResponse)

	const chartData = generateChartData(data.assessment)

	return (
		<>
			<div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
				<div className="rounded-2xl bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark col-span-2">
					<h2 className="mb-0.5 text-xl font-semibold text-black dark:text-white md:text-title-lg text-center">{`${assessment.assessmentType.position.role} ${assessment.assessmentType.position.level}`}</h2>
					<p className="text-sm font-medium text-center">Assessment type</p>
				</div>
				<div className="rounded-2xl bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
					<h4 className="mb-0.5 text-xl font-semibold text-black dark:text-white md:text-title-lg text-center">
						{result.managerAverageScore} %
					</h4>
					<p className="text-sm font-medium text-center">Manager score</p>
				</div>
				<div className="rounded-2xl bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
					<h4 className="mb-0.5 text-xl font-semibold text-black dark:text-white md:text-title-lg text-center">
						{result.employeeAverageScore} %
					</h4>
					<p className="text-sm font-medium text-center">Employee score</p>
				</div>
				<div className="rounded-2xl bg-white px-5 py-6 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 col-span-2 flex flex-col justify-center">
					<div className="border-stroke p-4 dark:border-strokedark">
						{result.averageByCategory.map((category: any) => (
							<div className="items-center sm:flex mb-2">
								<div className="flex w-full max-w-42.5 items-center gap-3.5">
									<p className="font-medium text-black dark:text-white">{category.category}</p>
								</div>
								<div className="relative block h-4.5 w-full rounded bg-meta-9 dark:bg-meta-4">
									<div
										className={`absolute left-0 top-0 flex h-full items-center justify-center rounded bg-primary text-xs font-medium text-white`}
										style={{ width: `${category.managerAverageScore}%` }}
									>
										{category.managerAverageScore}%
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="rounded-2xl bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark col-span-2">
					<RadarComponent chartData={chartData} square={false} />
				</div>
			</div>
			{result.averageByCategory.map((category: any) => (
				<Card>
					<h2 className="text-2xl mb-7 font-semibold text-black">{category.category}</h2>
					{category.subcategories.map((subcategory: any) => (
						<div className="rounded-2xl border border-stroke bg-white px-5 py-6 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 my-3">
							<div className="flex justify-between items-center mb-7">
								<h3 className="text-xl font-semibold text-black">{subcategory.category}</h3>
								<div className="flex items-center">
									<p className="text-sm font-medium text-center">Awarded score: </p>
									<h4 className="ml-3 text-xl font-semibold text-black dark:text-white text-center">{subcategory.managerAverageScore} %</h4>
								</div>
							</div>
							<AnswersTable answers={subcategory.answers} />
						</div>
					))}
				</Card>
			))}
		</>
	)
}

const AssessmentDetail = () => {
	const { assessmentId } = useParams()

	if (!assessmentId) {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Assessment" />
				<div className="flex flex-col gap-10">
					<Loader />
				</div>
			</DefaultLayout>
		)
	}

	const graphQlClient = useGraphQLClient()
	const { data } = useAssessmentQuery(graphQlClient, { assessmentId }) // TODO: Use more effective query

	if (!data) {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Assessment" />
				<div className="flex flex-col gap-10">
					<Loader />
				</div>
			</DefaultLayout>
		)
	}

	const isFilledOutByEmployee = isAssessmentFilledOutByUser(data.assessment as Assessment)
	const isFilledOutByManager = isAssessmentFilledOutByManager(data.assessment as Assessment)

	if (!isFilledOutByEmployee || !isFilledOutByManager) {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Assessment" />
				<div className="flex flex-col gap-10">
					<div>Waiting for all parties to fill out the assessment first</div>
				</div>
			</DefaultLayout>
		)
	}

	return (
		<DefaultLayout>
			<Breadcrumb pageName="Assessment" />
			<div className="flex flex-col gap-10">
				{isFilledOutByEmployee && isFilledOutByManager && (
					<Charts
						assessmentId={assessmentId}
						assessment={data.assessment}
						employeeResponse={data.assessment.userResponse}
						managerResponse={data.assessment.managerResponse}
					/>
				)}
			</div>
		</DefaultLayout>
	)
}

export default AssessmentDetail
