import LazyComponent from './LazyComponent.tsx'
import { useModalsList } from './selectors.ts'

export interface ModalMeta {
	[name: string]: any
}

export type Modal = {
	id: string
	open: boolean
	meta?: ModalMeta
}

export type ModalMap = {
	[id: string]: Modal
}

interface IModalProviderProps {
	children: React.ReactNode
}

function ModalProvider(props: IModalProviderProps) {
	const modals = useModalsList()

	return (
		<>
			{modals.map((filename) => (
				<LazyComponent key={filename} filename={filename} />
			))}
			{props.children}
		</>
	)
}

export default ModalProvider
