import React, { useCallback, useMemo, useState } from 'react'
import Header from '../components/Header/index'
import Sidebar from '../components/Sidebar/index'
import { useLogto, type IdTokenClaims } from '@logto/react'
import { useAuth } from '../AuthProvider.ts'

interface DefaultLayoutProps extends React.PropsWithChildren {
	user?: IdTokenClaims
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
	const { signOut } = useLogto()
	const { authUser } = useAuth()

	const isDevEnvironment = useMemo(() => {
		return location.hostname === 'localhost'
	}, [])

	const handleSignOut = useCallback(async () => {
		localStorage.removeItem('accessToken')
		localStorage.removeItem('organizationToken')
		await signOut(isDevEnvironment ? import.meta.env.VITE_LOCAL_REDIRECT_URL : import.meta.env.VITE_PROD_REDIRECT_URL)
	}, [signOut])

	const [sidebarOpen, setSidebarOpen] = useState(false)

	return (
		<div className="dark:bg-boxdark-2 dark:text-bodydark">
			{/* <!-- ===== Page Wrapper Start ===== --> */}
			<div className="flex h-screen overflow-hidden">
				{/* <!-- ===== Sidebar Start ===== --> */}
				<Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
				{/* <!-- ===== Sidebar End ===== --> */}

				{/* <!-- ===== Content Area Start ===== --> */}
				<div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
					{/* <!-- ===== Header Start ===== --> */}

					<Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} logout={handleSignOut} user={authUser} />
					{/* <!-- ===== Header End ===== --> */}

					{/* <!-- ===== Main Content Start ===== --> */}
					<main>
						<div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">{children}</div>
					</main>
					{/* <!-- ===== Main Content End ===== --> */}
				</div>
				{/* <!-- ===== Content Area End ===== --> */}
			</div>
			{/* <!-- ===== Page Wrapper End ===== --> */}
		</div>
	)
}

export default DefaultLayout
