import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'
import DefaultLayout from '../../layout/DefaultLayout'
import 'survey-core/defaultV2.min.css'
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'
import { useCallback } from 'react'
import { DefaultLight, DefaultDark } from 'survey-core/themes'
import { useGraphQLClient } from '../../useGraphQLClient.ts'
import { Question, useAssessmentQuery } from '../../generated/graphql.ts'
import Loader from '../../common/Loader'

interface Element {
	name: string
	title: string
	type: string
	rateCount?: number
	rateValues?: number[]
	isRequired: boolean
	description?: string
}

interface Page {
	name?: string
	title?: string
	elements: (Element | { type: string; html: string })[]
}

export interface SurveyJson {
	pages: Page[]
	showQuestionNumbers: string
	pageNextText: string
	completeText: string
	firstPageIsStarted: boolean
	startSurveyText: string
	completedHtml: string
	showPreviewBeforeComplete: string
	showProgressBar?: string
	progressBarType?: string
	progressBarShowPageNumbers?: boolean
	progressBarShowPageTitles?: boolean
}

const getQuestionType = (type: string | undefined | null) => {
	switch (type) {
		case 'text':
			return { type: 'text' }
		case 'range10':
		default:
			return { type: 'rating', rateCount: 10, rateValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }
	}
}

export const mapData = (questions: Question[]): SurveyJson => {
	const pages = questions.reduce((acc: Page[], item): Page[] => {
		const page = acc.find((pg: Page) => pg.name === `${item.name}-${item.subName}`)
		if (!page) {
			acc.push({
				title: `${item.name} / ${item.subName}`,
				name: `${item.name}-${item.subName}`,
				elements: [
					{
						name: item.id,
						title: item.text,
						...getQuestionType(item.responseType),
						isRequired: true,
						description: '1 = lowest; 10 = highest',
					},
				],
			})
		} else {
			page.elements.push({
				name: item.id,
				title: item.text,
				type: 'rating',
				rateCount: 10,
				rateValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
				isRequired: true,
				description: '1 = lowest; 10 = highest',
			})
		}
		return acc
	}, [])

	return {
		pages: [
			{
				elements: [
					{
						type: 'html',
						html: '<h4>Intro</h4>',
					},
				],
			},
			...pages.flat(),
		],
		showQuestionNumbers: 'on',
		pageNextText: 'Next',
		completeText: 'Submit',
		firstPageIsStarted: true,
		startSurveyText: 'Start',
		completedHtml: 'Thank you for your feedback!',
		showPreviewBeforeComplete: 'showAnsweredQuestions',
		showProgressBar: 'belowHeader',
		progressBarType: 'pages',
		progressBarShowPageNumbers: true,
		progressBarShowPageTitles: false,
	}
}

const SURVEY_ID = 1

interface SurveyLayerProps {
	data: SurveyJson
}

export const SurveyLayer = ({ data }: SurveyLayerProps) => {
	const survey = new Model(data)
	const surveyComplete = useCallback((sender: any) => {
		console.log({
			[SURVEY_ID]: sender.data,
		})
	}, [])

	survey.onComplete.add(surveyComplete)
	survey.applyTheme(DefaultDark)

	return <Survey model={survey} />
}

const Surveys = () => {
	const graphQlClient = useGraphQLClient()
	const { data: assessmentsQuery } = useAssessmentQuery(graphQlClient, { assessmentId: 'c6b7fd49-e7bf-46b3-83d3-828f42d2d271' })

	return (
		<DefaultLayout>
			<Breadcrumb pageName="Surveys" />

			<div className="rounded-sm border border-stroke bg-white p-4 shadow-default dark:border-strokedark dark:bg-boxdark md:p-6 xl:p-9">
				<div className="flex flex-col gap-7.5">
					{assessmentsQuery?.assessment && <SurveyLayer data={mapData(assessmentsQuery.assessment.assessmentType.questions)} />}
					{!assessmentsQuery?.assessment && <Loader />}
				</div>
			</div>
		</DefaultLayout>
	)
}

export default Surveys
