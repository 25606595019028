import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { NEXTPEAK_API_URL } from '../config'

// Get all users from Logto
const fetchLogtoUsers = async () => {
	const response = await fetch(`${NEXTPEAK_API_URL}/api/logto/users`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	})
	if (!response.ok) {
		throw new Error('Network response was not ok')
	}
	return response.json()
}

export const useLogtoUsers = () => {
	return useQuery({ queryKey: ['LogtoUsers'], queryFn: fetchLogtoUsers })
}

// Delete User at Logto
const deleteUser = async (userId) => {
	const response = await fetch(`${NEXTPEAK_API_URL}/api/logto/user?userId=${userId}`, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
		},
	})
	if (response.status === 204) {
		return response.text() // Return a success message
	}
	if (!response.ok) {
		throw new Error('Network response was not ok')
	}
}

export const useDeleteUser = () => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (userId) => deleteUser(userId),
		onSuccess: () => {
			// Invalidate and refetch the LogtoUsers query to update the list after deletion
			queryClient.invalidateQueries(['LogtoUsers'])
		},
	})
}

//Get user organization from user Id
const fetchUserOrganizations = async (token: string) => {
	const response = await fetch(`${NEXTPEAK_API_URL}/api/auth/organizations`, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})
	if (!response.ok) {
		throw new Error('Network response was not ok')
	}
	return response.json()
}

export const useOrganizationsByToken = (token: string | undefined) => {
	return useQuery({
		queryKey: ['AuthUserOrganization', token],
		queryFn: () => token && fetchUserOrganizations(token),
		enabled: !!token, // Only run this query if token is not null or undefined
	})
}
