import dayjs from 'dayjs'
import { AssessmentWithResponsesQuery } from '../../generated/graphql.ts'

export const snakeToWords = (str: string) => {
	// Split the string by underscore
	let words = str.split('_')

	// Capitalize the first word and keep the rest in lowercase
	words = words.map((word, index) => {
		return index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase()
	})

	// Join the words back with spaces
	return words.join(' ')
}

export const toSnakeCase = (str: string): string => {
	return str.toLowerCase().replace(/\s+/g, '_')
}

export const toCamelCase = (str: string): string => {
	return str
		.toLowerCase()
		.split(' ')
		.map((word, index) => {
			if (index === 0) {
				return word
			}
			return word.charAt(0).toUpperCase() + word.slice(1)
		})
		.join('')
}

export const toCapitalized = (str: string): string => {
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const fromCamelCase = (str: string): string => {
	return str
		.replace(/([A-Z])/g, ' $1')
		.toLowerCase()
		.trim()
}

export function generateChartData(assessment: AssessmentWithResponsesQuery['assessment']) {
	const userResponseId = assessment.userResponse?.id
	const managerResponseId = assessment.managerResponse?.id

	const userResponse = assessment.assessmentResponses.find((response) => response.id === userResponseId)
	const managerResponse = assessment.assessmentResponses.find((response) => response.id === managerResponseId)

	// Assuming both user and manager have the same categories structure
	return userResponse?.averageByCategory.map((userCategory) => {
		const managerCategory = managerResponse?.averageByCategory.find((managerCategory) => managerCategory.category === userCategory.category)

		return {
			category: userCategory.category,
			user: userCategory.average,
			manager: managerCategory?.average,
		}
	})
}

export const formatTimestamp = (timestamp: string | number, format: string) => {
	const date = dayjs(Number(timestamp))
	return date.format(format)
}
