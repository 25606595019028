import { createContext, useContext } from 'react'
import { MeQuery, Organization } from './generated/graphql.ts'

interface Me {
	id: string | undefined
	isManager: boolean | undefined
	isAdmin: boolean | undefined
	email: string | undefined
	firstName: string | undefined
	lastName: string | undefined
	organizations: Organization[]
	activeOrg?: Organization | null
	organizationRoles: string[]
	userPosition: MeQuery['me']['userPosition']
}

const MeContext = createContext<Me>({
	id: undefined,
	isManager: undefined,
	isAdmin: undefined,
	email: undefined,
	firstName: undefined,
	lastName: undefined,
	organizations: [],
	activeOrg: null,
	organizationRoles: [],
	userPosition: null,
})

const getMe = (): Me => {
	return useContext(MeContext)
}

export { MeContext, getMe }
