import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'
import DefaultLayout from '../../layout/DefaultLayout'
import { Assessment, UserAssessmentsQuery, useUserAssessmentsQuery } from '../../generated/graphql.ts'
import { useGraphQLClient } from '../../useGraphQLClient.ts'
import Loader from '../../common/Loader'
import { Link } from 'react-router-dom'
import Label from '../../components/Label.tsx'
import { isAssessmentFilledOutByManager, isAssessmentFilledOutByUser } from '../../utils.ts'
import { getAssessmentResponseByUserId } from './utils.ts'
import EmptyTableRow from '../../components/EmptyTableRow.tsx'
import { getMe } from '../../MeProvider.ts'
import { useEffect, useState } from 'react'

interface AssessmentRowProps {
	assessment: Assessment
	userId: string
}

const AssessmentRow = ({ assessment, userId }: AssessmentRowProps) => {
	const getAssessmentFilledStatusLabel = (assessment: Assessment) => {
		if (isAssessmentFilledOutByUser(assessment)) {
			if (isAssessmentFilledOutByManager(assessment)) {
				return <Label type="success">Completed</Label>
			}
			return <Label type="warning">Waiting for manager</Label>
		}
		if (isAssessmentFilledOutByManager(assessment)) {
			return <Label type="warning">Waiting for employee</Label>
		}
		return <Label type="info">Pending</Label>
	}

	const myResponse = getAssessmentResponseByUserId(assessment, userId!)

	const getActionLink = () => {
		if (!myResponse || myResponse?.completedAt) {
			return `/assessments/${assessment.id}`
		}

		return `/assessments/${assessment.id}/responses/${myResponse.id}`
	}

	return (
		<tr>
			<td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
				<h5 className="font-medium text-black dark:text-white">{assessment.user.firstName + ' ' + assessment.user.lastName}</h5>
			</td>
			<td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
				<p className="text-black dark:text-white">{assessment.assessmentType.position.title}</p>
			</td>
			<td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
				<p className="text-black dark:text-white">{assessment.assessmentType.position.level}</p>
			</td>
			<td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
				{getAssessmentFilledStatusLabel(assessment as Assessment)}
			</td>
			<td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
				<Link className="hover:text-primary" to={getActionLink()}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke-width="1.5"
						stroke="currentColor"
						className="size-6"
					>
						<path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							d="M15.91 11.672a.375.375 0 0 1 0 .656l-5.603 3.113a.375.375 0 0 1-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112Z"
						/>
					</svg>
				</Link>
			</td>
		</tr>
	)
}

export const AssessmentsTable = ({ assessments }: { assessments: UserAssessmentsQuery['userAssessments'] }) => {
	const me = getMe()

	return (
		<div className="rounded-2xl bg-white shadow-default dark:border-strokedark dark:bg-boxdark my-3 overflow-hidden">
			<div className="max-w-full overflow-x-auto">
				<table className="w-full table-auto">
					<thead>
						<tr className="bg-gray-2 text-left dark:bg-meta-4">
							<th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">Name</th>
							<th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white">Title</th>
							<th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">Position</th>
							<th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">Status</th>
							<th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">Action</th>
						</tr>
					</thead>
					<tbody>
						{!!assessments.length &&
							assessments.map((assessment) => <AssessmentRow assessment={assessment as Assessment} key={assessment.id} userId={me.id!} />)}
						{!assessments.length && <EmptyTableRow emptyText="No assessments found" />}
					</tbody>
				</table>
			</div>
		</div>
	)
}

interface TabbedTablesViewProps {
	employeeAssessments: UserAssessmentsQuery['userAssessments']
	managerAssessments: UserAssessmentsQuery['userAssessments']
}

const TabbedTablesView = ({ employeeAssessments, managerAssessments }: TabbedTablesViewProps) => {
	const [openTab, setOpenTab] = useState(1)

	useEffect(() => {
		if (!employeeAssessments.length && managerAssessments.length) {
			setOpenTab(2)
			console.log('Pls no loop')
		}
	}, [])

	const activeClasses = 'text-primary border-primary'
	const inactiveClasses = 'border-transparent'

	return (
		<div>
			<div className="mb-6 flex flex-wrap gap-5 border-b border-stroke dark:border-strokedark sm:gap-10">
				<Link
					to="#"
					className={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base ${
						openTab === 1 ? activeClasses : inactiveClasses
					}`}
					onClick={() => setOpenTab(1)}
				>
					Personal
				</Link>
				{!!managerAssessments.length && (
					<Link
						to="#"
						className={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base ${
							openTab === 2 ? activeClasses : inactiveClasses
						}`}
						onClick={() => setOpenTab(2)}
					>
						Subordinates
					</Link>
				)}
			</div>
			<div>
				<div className={`${openTab === 1 ? 'block' : 'hidden'}`}>
					<AssessmentsTable assessments={employeeAssessments} />
				</div>
				<div className={`${openTab === 2 ? 'block' : 'hidden'}`}>
					<AssessmentsTable assessments={managerAssessments} />
				</div>
			</div>
		</div>
	)
}

const Assessments = () => {
	const me = getMe()
	const graphQlClient = useGraphQLClient()
	const { data: employeeAssessments, isLoading: isEmpLoading } = useUserAssessmentsQuery(graphQlClient, {
		userId: me.id!, // TODO: should be type checked to non-falsy,
		userIsManager: false,
	})

	const { data: managerAssessments, isLoading: isManLoading } = useUserAssessmentsQuery(graphQlClient, {
		userId: me.id!, // TODO: should be type checked to non-falsy,
		userIsManager: true,
	})

	return (
		<DefaultLayout>
			<Breadcrumb pageName="Assessments" />
			<div className="flex flex-col gap-10">
				{(isEmpLoading || isManLoading) && <Loader />}
				{!isEmpLoading && !isManLoading && employeeAssessments && managerAssessments && (
					<TabbedTablesView
						employeeAssessments={employeeAssessments.userAssessments}
						managerAssessments={managerAssessments.userAssessments}
					/>
				)}
			</div>
		</DefaultLayout>
	)
}

export default Assessments
