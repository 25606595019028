import { AxiosRequestConfig } from 'axios'
import { IAuthenticator } from './interfaces'
import { HttpClient } from '../http.client'
import { IRequestConfig } from '../interfaces'

export class NoAuthAuthenticator implements IAuthenticator {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	async processAuth<D>(_httpClient: HttpClient, config: IRequestConfig<D>): Promise<AxiosRequestConfig> {
		return config
	}

	// eslint-disable-next-line no-use-before-define
	private static _instance: NoAuthAuthenticator = new NoAuthAuthenticator()

	static get default(): NoAuthAuthenticator {
		return this._instance
	}
}
