import { GraphQLClient } from 'graphql-request'
import { useMemo } from 'react'
import { NEXTPEAK_API_URL } from './config'

export const useGraphQLClient = () => {
	const clientToken = window.localStorage.getItem('accessToken')
	const orgToken = window.localStorage.getItem('organizationToken')
	return useMemo(() => {
		const options = {
			headers: new Headers(),
		}

		if (orgToken) {
			options.headers.append('Authorization', `Bearer ${orgToken}`)
		} else if (clientToken) {
			options.headers.append('Authorization', `Bearer ${clientToken}`)
		}

		return new GraphQLClient(NEXTPEAK_API_URL, options)
	}, [orgToken, clientToken])
}
