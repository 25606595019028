import { useCallback } from 'react'
import { useModalContext } from './modalContext.tsx'
import { ModalMeta } from './ModalProvider.tsx'

export interface ModalProps {
	isOpen: boolean
	onOpen: (meta: ModalMeta) => void
	onClose: () => void
}

export function useModal(modalFileName: string): ModalProps {
	const { openModal, closeModal, state } = useModalContext()

	const onOpen = useCallback((meta: ModalMeta) => openModal(modalFileName, meta), [modalFileName, openModal])

	const onClose = useCallback(() => closeModal(modalFileName), [modalFileName, closeModal])

	const isOpen = state.modal[modalFileName]?.open ?? false

	return {
		isOpen,
		onOpen,
		onClose,
	}
}
