import DefaultLayout from '../../layout/DefaultLayout.tsx'
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb.tsx'
import { useRestClient } from '../../useRestClient.ts'
import { useGetOrganizationInvites } from '../../http/hooks.ts'
import { useOrgAuth } from '../../OrgAuthProvider.ts'
import { IOrganizationInvite } from '../../services/nextpeakClient'
import Card from '../../components/Card.tsx'
import EmptyTableRow from '../../components/EmptyTableRow.tsx'
import Loader from '../../common/Loader'
import { getMe } from '../../MeProvider.ts'
import { useModal } from '../../components/modals/useModal.ts'

const InviteRow = ({ invite }: { invite: IOrganizationInvite }) => {
	return (
		<tr>
			<td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
				<p className="text-black dark:text-white">{invite.email}</p>
			</td>
			<td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
				<p className="text-black dark:text-white">{invite.inviteStatus}</p>
			</td>
			<td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
				<button className="text-primary">Resend</button>
			</td>
		</tr>
	)
}

const InviteTable = ({ invites }: { invites: IOrganizationInvite[] }) => {
	return (
		<Card padding={false}>
			<div className="max-w-full overflow-x-auto">
				<table className="w-full table-auto">
					<thead>
						<tr className="bg-gray-2 text-left dark:bg-meta-4">
							<th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">Email</th>
							<th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">Status</th>
							<th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">Action</th>
						</tr>
					</thead>
					<tbody>
						{!!invites.length && invites.map((invite, key) => <InviteRow invite={invite} key={key} />)}
						{!invites.length && <EmptyTableRow emptyText="No invites found" />}
					</tbody>
				</table>
			</div>
		</Card>
	)
}

const InvitesWithOrgContext = ({ activeOrganizationId }: { activeOrganizationId: string }) => {
	const me = getMe()
	const { onOpen } = useModal('CreateInviteModal')
	const restClient = useRestClient()
	const { data, isLoading } = useGetOrganizationInvites(restClient, activeOrganizationId)

	const iamManager = me.organizationRoles.includes('manager')
	const iamAdmin = me.organizationRoles.includes('admin')

	const iCanInvite = iamManager || iamAdmin

	return (
		<DefaultLayout>
			<Breadcrumb pageName="Invites" />
			<div>
				{isLoading && <Loader />}
				{!isLoading && data && (
					<>
						{iCanInvite && (
							<div className="flex justify-end">
								<button
									onClick={() => onOpen({ organizationId: activeOrganizationId })}
									className="inline-flex items-center justify-center rounded-md bg-meta-3 px-6 py-2 text-center font-medium text-white hover:bg-opacity-90"
								>
									Invite
								</button>
							</div>
						)}
						<InviteTable invites={data} />
					</>
				)}
			</div>
		</DefaultLayout>
	)
}

const InvitesList = () => {
	const {
		authOrgUser: { internalOrgId },
	} = useOrgAuth()

	if (!internalOrgId) {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Employees" />

				<div className="flex flex-col gap-10">
					<div>You need to select an active organization first</div>
				</div>
			</DefaultLayout>
		)
	}

	return <InvitesWithOrgContext activeOrganizationId={internalOrgId} />
}

export default InvitesList
