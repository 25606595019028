import { useMutation, useQuery } from '@tanstack/react-query'
import {
	NextpeakClient,
	ICreateOrgInviteRequest,
	IInviteAction,
	ITrajectory,
	IOrganizationInvite,
	IPosition,
} from '../services/nextpeakClient'
import { toast } from 'react-toastify'

export const useGetOrganizationUsers = (client: NextpeakClient, organizationId: string, managerId?: string) => {
	return useQuery<unknown, Error, any[]>({
		queryKey: ['GetOrganizationUsers', organizationId, managerId],
		queryFn: () => client.getOrganizationUsers(organizationId, managerId),
		enabled: !!organizationId,
	})
}

export const useCreateOrgInvite = (client: NextpeakClient) => {
	return useMutation({
		mutationFn: (inviteData: ICreateOrgInviteRequest) => client.createOrganizationInvite(inviteData),
		onSuccess: (res) => {
			console.log(res)
			toast.success('Invite created')
		},
		onError: (error) => {
			// Optionally handle errors here, like showing a notification
			console.error('Create of org Invite failed:', error)
			toast.error('Create of org Invite failed')
		},
	})
}

export const useGetOrganizationRoles = (client: NextpeakClient) => {
	return useQuery<unknown, Error, any[]>({ queryKey: ['GetOrganizationRoles'], queryFn: () => client.getOrganizationRoles() })
}

export const useGetOrganizationInvite = (client: NextpeakClient, inviteId: string) => {
	return useQuery<unknown, Error, any>({
		queryKey: ['GetOrganizationInvite', inviteId],
		queryFn: () => client.getOrganizationInvite(inviteId),
	})
}

export const useGetUserOrganizationRoles = (client: NextpeakClient, userId?: string) => {
	return useQuery<unknown, Error, any[]>({
		queryKey: ['GetUserOrganizationRoles', userId],
		queryFn: () => client.getUserOrganizationRoles(userId!),
		enabled: !!userId,
	})
}

export const useUpdateOrgInvite = (client: NextpeakClient) => {
	return useMutation({
		mutationFn: ({ inviteId, inviteData }: { inviteId: string; inviteData: IInviteAction }) =>
			client.updateOrganizationInvite(inviteId, inviteData),
		onSuccess: (res) => {
			console.log(res)
			toast.success('Invite successfully updated')
		},
		onError: (error) => {
			// Optionally handle errors here, like showing a notification
			console.error(error)
			toast.error('Update of org Invite failed')
		},
	})
}

export const useUpdateUserOrganizationRoles = (client: NextpeakClient) => {
	return useMutation({
		mutationFn: ({ userId, roleIds }: { userId: string; roleIds: string[] }) => client.updateUserOrganizationRoles(userId, roleIds),
		onSuccess: (res) => {
			toast.success('User roles updated')
		},
		onError: (error) => {
			console.error(error)
			toast.error('Roles update failed')
		},
	})
}

export const useGetUser = (client: NextpeakClient, userId: string) => {
	return useQuery<unknown, Error, any>({ queryKey: ['GetUser', userId], queryFn: () => client.getUser(userId) })
}

export const usePromoteUser = (client: NextpeakClient) => {
	return useMutation({
		mutationFn: ({ userId }: { userId: string }) => client.promoteUser(userId),
		onSuccess: (res) => {
			toast.success('User promoted')
		},
		onError: (error) => {
			console.error(error)
			toast.error('Promotion failed')
		},
	})
}

export const useUpdateUserManager = (client: NextpeakClient) => {
	return useMutation({
		mutationFn: ({ userId, newManagerId }: { userId: string; newManagerId: string }) => client.updateUserManager(userId, newManagerId),
		onSuccess: (res) => {
			toast.success('User promoted')
		},
		onError: (error) => {
			console.error(error)
			toast.error('Promotion failed')
		},
	})
}

export const useGetOrganizationPublic = (client: NextpeakClient, organizationId?: string) => {
	return useQuery<unknown, Error, any>({
		queryKey: ['GetOrganizationPublic', organizationId],
		queryFn: () => client.getOrganizationPublic(organizationId!),
		enabled: !!organizationId,
	})
}

export const useGetFullTrajectory = (client: NextpeakClient, path: string) => {
	return useQuery<unknown, Error, ITrajectory[]>({ queryKey: ['GetFullTrajectory', path], queryFn: () => client.getFullTrajectory(path) })
}

export const useGetOrganizationInvites = (client: NextpeakClient, organizationId: string) => {
	return useQuery<unknown, Error, IOrganizationInvite[]>({
		queryKey: ['GetOrganizationInvites', organizationId],
		queryFn: () => client.getOrganizationInvites(organizationId),
	})
}

export const useGetPosition = (client: NextpeakClient, positionId: string) => {
	return useQuery<unknown, Error, IPosition>({ queryKey: ['GetPosition', positionId], queryFn: () => client.getPosition(positionId) })
}
