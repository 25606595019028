import { AxiosRequestConfig } from 'axios'
import { IAuthenticator } from './interfaces'
import { HttpClient } from '../http.client'
import { IRequestConfig } from '../interfaces'

export interface IBearerAuthenticatorOpts {
	/**
	 * If token is about to expire in less than this number of seconds, it will be refreshed.
	 * Default is 60 seconds.
	 */
	tokenExpirationShortageSeconds?: number
}

export interface IBearerTokenInfo {
	token: string
	expiresInSeconds: number
}

interface IBearerTokenInfoInternal {
	token: string
	expiryAt: Date
}

export class BearerAuthenticator implements IAuthenticator {
	constructor(
		private readonly authCallback: (httpClient: HttpClient) => Promise<IBearerTokenInfo>,
		private readonly opts?: IBearerAuthenticatorOpts
	) {}

	private tokenInfo: IBearerTokenInfoInternal | undefined

	async processAuth<D>(httpClient: HttpClient, config: IRequestConfig<D>): Promise<AxiosRequestConfig> {
		if (!this.tokenInfo || this.tokenInfo.expiryAt < new Date()) {
			const now = new Date()
			const newTokenInfo = await this.authCallback(httpClient)
			const shortageSeconds = this.opts?.tokenExpirationShortageSeconds ?? 60
			this.tokenInfo = {
				token: newTokenInfo.token,
				expiryAt: new Date(now.getTime() + newTokenInfo.expiresInSeconds * 1000 - shortageSeconds * 1000),
			}
		}

		return {
			...config,
			headers: {
				...config.headers,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				Authorization: `Bearer ${this.tokenInfo.token}`,
			},
		}
	}
}
