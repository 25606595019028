import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useLogto } from '@logto/react'
import Card from '../../components/Card.tsx'

const SignIn: React.FC = () => {
	const { signIn } = useLogto()
	const [searchParams] = useSearchParams()
	const isDevEnvironment = useMemo(() => {
		return location.hostname === 'localhost'
	}, [])

	const redirect = searchParams.get('redirect')
	if (redirect) {
		console.log('sett')
		localStorage.setItem('postLoginRedirect', redirect)
	}

	return (
		<div className="w-[100%] min-h-dvh p-7 flex justify-center items-center">
			<div className="max-w-100">
				<Card>
					<h2 className="text-center mb-9 text-2xl font-bold text-black dark:text-white">Sign in to NextPeak</h2>
					<button
						onClick={() => signIn(isDevEnvironment ? import.meta.env.VITE_LOCAL_REDIRECT_URL : import.meta.env.VITE_PROD_REDIRECT_URL)}
						className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 font-medium text-white transition hover:bg-opacity-90"
					>
						Sign In
					</button>
				</Card>
			</div>
		</div>
	)
}

export default SignIn
