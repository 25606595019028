import { ErrorBoundary } from '@sentry/react'
import React, { Suspense } from 'react'
import { useModal } from './useModal.ts'
import { useModalMeta } from './selectors.ts'

interface ILazyComponentProps {
	filename: string
}

function LazyComponent({ filename }: ILazyComponentProps) {
	const Component = React.lazy(() => import(`./${filename}/${filename}.tsx`))
	const modalProps = useModal(filename)
	const metaProps = useModalMeta(filename)

	return (
		<Suspense fallback={null}>
			<ErrorBoundary>{filename ? <Component {...modalProps} {...metaProps} /> : null}</ErrorBoundary>
		</Suspense>
	)
}

export default LazyComponent
