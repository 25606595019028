'use client'

import { PolarAngleAxis, PolarGrid, Radar, RadarChart } from 'recharts'
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '../ui/chart'

export const description = 'A radar chart'

const chartConfig = {
	mobile: {
		label: 'Manager',
		color: '#3056D3',
	},
	desktop: {
		label: 'User',
		color: '#80CAEE',
	},
} satisfies ChartConfig

export function RadarComponent({ chartData, square = true }: { chartData: any[]; square?: boolean }) {
	return (
		<ChartContainer config={chartConfig} className={`mx-auto ${square ? 'aspect-square' : ''}`} style={{ width: '100%', height: '100%' }}>
			<RadarChart data={chartData}>
				<ChartTooltip cursor={false} content={<ChartTooltipContent indicator="line" />} />
				<PolarAngleAxis dataKey="category" />
				<PolarGrid />
				<Radar
					dataKey="user"
					fill="var(--color-desktop)"
					fillOpacity={0.6}
					dot={{
						r: 4,
						fillOpacity: 0.6,
					}}
				/>
				<Radar
					dataKey="manager"
					fillOpacity={0.6}
					fill="var(--color-mobile)"
					dot={{
						r: 4,
						fillOpacity: 1,
					}}
				/>
			</RadarChart>
		</ChartContainer>
	)
}
