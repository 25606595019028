import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'
import DefaultLayout from '../../layout/DefaultLayout'
import Org from '../../pages/Organization/Org'

const OrganizationOverview = () => {
	return (
		<DefaultLayout>
			<Breadcrumb pageName="Organization graph" />
			<div className="flex flex-col justify-center items-center">
				<div className="w-full">This page is under construction</div>
			</div>
			{/*<Org />*/}
		</DefaultLayout>
	)
}

export default OrganizationOverview
