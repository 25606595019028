import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils'

export const promoteUser = async (client: HttpClient, userId: string) => {
	const response = await client.post(`/api/users/${userId}/promote`)

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
