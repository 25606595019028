export class InvalidContentTypeException extends Error {
	constructor(allowedContentTypes: string[], receiverContentType: string) {
		super(`Invalid content type. Allowed: ${allowedContentTypes.join(', ')}, received: ${receiverContentType}`)
	}
}

export class UnexpectedHttpStatusCodeException extends Error {
	public constructor(
		public readonly statusCode: number,
		readonly extraMessage?: string
	) {
		const defaultExceptionMessage = `Unexpected status code ${statusCode}`
		const message = extraMessage ? `${defaultExceptionMessage}: ${extraMessage}` : defaultExceptionMessage
		super(message)
	}
}
