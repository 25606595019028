import { AxiosResponse } from 'axios'
import { InvalidContentTypeException, UnexpectedHttpStatusCodeException } from './exceptions.ts'

export const validateContentType = (contentType: string | undefined | null, allowedContentTypes: string[]): boolean => {
	if (!contentType) return false
	const contentTypeParts = contentType.split(';')
	const contentTypeWithoutCharset = contentTypeParts[0]
	if (!contentTypeWithoutCharset) return false
	return allowedContentTypes.map((allowedType) => allowedType.toLowerCase()).includes(contentTypeWithoutCharset.toLowerCase())
}

export const throwIfNotAllowedContentType = (axiosResponse: AxiosResponse, allowedContentTypes: string[]): void => {
	const contentType = axiosResponse.headers['content-type']
	if (!validateContentType(contentType, allowedContentTypes)) {
		throw new InvalidContentTypeException(allowedContentTypes, contentType)
	}
}

export const defaultResponseValidator = <T>(response: AxiosResponse<T, unknown>) => response.status >= 200 && response.status < 300

export const defaultErrorMessageFormatter = async <T>(response: AxiosResponse<T, unknown>): Promise<string> => JSON.stringify(response.data)

export const validateHttpResponse = async <T = unknown, U = unknown>(response: AxiosResponse<T, U>): Promise<void> => {
	const isResponseValid = defaultResponseValidator(response)

	if (!isResponseValid) {
		const errorMessage = await defaultErrorMessageFormatter(response)
		throw new UnexpectedHttpStatusCodeException(response.status, errorMessage)
	}
}
