import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'
import DefaultLayout from '../../layout/DefaultLayout'

const Billing = () => {
	return (
		<DefaultLayout>
			<Breadcrumb pageName="Billing" />
		</DefaultLayout>
	)
}

export default Billing
