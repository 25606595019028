import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'

export const getOrganizationInvite = async (client: HttpClient, inviteId: string) => {
	const response = await client.get(`/api/invites/${inviteId}`)

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
