import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'
import DefaultLayout from '../../layout/DefaultLayout'
import { User } from '../../generated/graphql.ts'
import Loader from '../../common/Loader'
import { Link } from 'react-router-dom'
import EmptyTableRow from '../../components/EmptyTableRow.tsx'
import Card from '../../components/Card.tsx'
import { useOrgAuth } from '../../OrgAuthProvider.ts'
import { useRestClient } from '../../useRestClient.ts'
import { useGetOrganizationUsers } from '../../http/hooks.ts'
import Label from '../../components/Label.tsx'

interface EmployeeRowProps {
	employee: User
}

const EmployeeRow = ({ employee }: EmployeeRowProps) => {
	const getRoleBadge = (employee: any) => {
		const mappedRoles: any[] = employee.organizationRoles.map((role: any) => role.name)
		const badges = []
		if (mappedRoles.includes('admin')) {
			badges.push(<Label type="danger">admin</Label>)
		}
		if (mappedRoles.includes('manager')) {
			badges.push(<Label type="warning">manager</Label>)
			return badges
		}
		badges.push(<Label type="success">employee</Label>)
		return badges
	}

	return (
		<tr>
			<td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
				<Link to={`/employees/${employee.id}`} className="font-medium text-black dark:text-white">
					{employee.firstName + ' ' + employee.lastName}
				</Link>
			</td>
			<td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
				<p className="text-black dark:text-white">{employee.email}</p>
			</td>
			<td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">{...getRoleBadge(employee)}</td>
			<td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
				<Link className="hover:text-primary" to={'/employees/' + employee.id}>
					<svg className="fill-current" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M8.99981 14.8219C3.43106 14.8219 0.674805 9.50624 0.562305 9.28124C0.47793 9.11249 0.47793 8.88749 0.562305 8.71874C0.674805 8.49374 3.43106 3.20624 8.99981 3.20624C14.5686 3.20624 17.3248 8.49374 17.4373 8.71874C17.5217 8.88749 17.5217 9.11249 17.4373 9.28124C17.3248 9.50624 14.5686 14.8219 8.99981 14.8219ZM1.85605 8.99999C2.4748 10.0406 4.89356 13.5562 8.99981 13.5562C13.1061 13.5562 15.5248 10.0406 16.1436 8.99999C15.5248 7.95936 13.1061 4.44374 8.99981 4.44374C4.89356 4.44374 2.4748 7.95936 1.85605 8.99999Z"
							fill=""
						/>
						<path
							d="M9 11.3906C7.67812 11.3906 6.60938 10.3219 6.60938 9C6.60938 7.67813 7.67812 6.60938 9 6.60938C10.3219 6.60938 11.3906 7.67813 11.3906 9C11.3906 10.3219 10.3219 11.3906 9 11.3906ZM9 7.875C8.38125 7.875 7.875 8.38125 7.875 9C7.875 9.61875 8.38125 10.125 9 10.125C9.61875 10.125 10.125 9.61875 10.125 9C10.125 8.38125 9.61875 7.875 9 7.875Z"
							fill=""
						/>
					</svg>
				</Link>
			</td>
		</tr>
	)
}

const EmployeesTable = ({ employees }: { employees: User[] }) => {
	return (
		<Card padding={false}>
			<div className="max-w-full overflow-x-auto">
				<table className="w-full table-auto">
					<thead>
						<tr className="bg-gray-2 text-left dark:bg-meta-4">
							<th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">Name</th>
							<th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">Email</th>
							<th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">Role</th>
							<th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">Action</th>
						</tr>
					</thead>
					<tbody>
						{!!employees.length && employees.map((employee, key) => <EmployeeRow employee={employee as User} key={key} />)}
						{!employees.length && <EmptyTableRow emptyText="No employees found" />}
					</tbody>
				</table>
			</div>
		</Card>
	)
}

const EmployeesWithOrgContext = ({ activeOrganizationId }: { activeOrganizationId: string }) => {
	const restClient = useRestClient()
	const { data: employees } = useGetOrganizationUsers(restClient, activeOrganizationId)

	return (
		<DefaultLayout>
			<Breadcrumb pageName="Employees" />

			<div className="flex flex-col gap-10">
				{!employees && (
					<Card>
						<Loader />
					</Card>
				)}
				{employees && <EmployeesTable employees={employees as User[]} />}
			</div>
		</DefaultLayout>
	)
}

const EmployeesList = () => {
	const {
		authOrgUser: { internalOrgId },
	} = useOrgAuth()

	if (!internalOrgId) {
		return (
			<DefaultLayout>
				<Breadcrumb pageName="Employees" />

				<div className="flex flex-col gap-10">
					<div>You need to select an active organization first</div>
				</div>
			</DefaultLayout>
		)
	}

	return <EmployeesWithOrgContext activeOrganizationId={internalOrgId} />
}

export default EmployeesList
