import axios, { Axios, AxiosRequestConfig, AxiosResponse } from 'axios'
import { IHttpClientOpts, IRequestConfig } from './interfaces'

export class HttpClient {
	readonly client: Axios

	constructor(
		public readonly baseUrl: string,
		protected readonly clientOpts: IHttpClientOpts = {}
	) {
		this.client = axios.create({
			baseURL: baseUrl,
			timeout: 30000,
			validateStatus: () => true,
			...clientOpts.axiosConfig,
		})
	}

	public async get<T = unknown, D = unknown>(url: string, config?: IRequestConfig<D>): Promise<AxiosResponse<T, D>> {
		const axiosConfig: AxiosRequestConfig = { ...config, method: 'GET', url }
		return this.handleRequest(axiosConfig)
	}

	async post<T = unknown, D = unknown>(url: string, data?: D, config?: IRequestConfig<D>): Promise<AxiosResponse<T, D>> {
		const axiosConfig: AxiosRequestConfig<D> = { ...config, method: 'POST', url, data }
		return this.handleRequest(axiosConfig)
	}

	async delete<T = unknown, D = unknown>(url: string, config?: IRequestConfig<D>): Promise<AxiosResponse<T, D>> {
		const axiosConfig: AxiosRequestConfig<D> = { ...config, method: 'DELETE', url }
		return this.handleRequest(axiosConfig)
	}

	async put<T = unknown, D = unknown>(url: string, data?: D, config?: IRequestConfig<D>): Promise<AxiosResponse<T, D>> {
		const axiosConfig: AxiosRequestConfig<D> = { ...config, method: 'PUT', url, data }
		return this.handleRequest(axiosConfig)
	}

	private async handleRequest<T, D>(config: AxiosRequestConfig<D>): Promise<AxiosResponse<T, D>> {
		try {
			const axiosConfig = await this.processAuthAndGetConfig(config)
			return this.client.request(axiosConfig)
		} catch (e) {
			console.log(e)
			throw e
		}
	}

	private async processAuthAndGetConfig<D>(config: any = {}): Promise<AxiosRequestConfig<D>> {
		const authenticator = config?.authenticator ?? this.clientOpts?.defaultAuthenticator
		if (!authenticator) return config
		return authenticator.processAuth(this, config)
	}
}
