import { createContext, useReducer, useContext } from 'react'
import { initialState, modalReducer } from './modalReducer.ts'
import { ModalMeta } from './ModalProvider.tsx'

interface ModalContextType {
	state: any
	openModal: (modalFileName: string, meta: ModalMeta) => void
	closeModal: (modalFileName: string) => void
}

const ModalContext = createContext<ModalContextType | undefined>(undefined)

export const ModalContextProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer(modalReducer, initialState)

	const openModal = (modalFileName: string, meta: ModalMeta) => {
		dispatch({ type: 'MODAL_OPEN', payload: { modalFileName, meta } })
	}

	const closeModal = (modalFileName: string) => {
		dispatch({ type: 'MODAL_CLOSE', payload: { modalFileName } })
	}

	return <ModalContext.Provider value={{ state, openModal, closeModal }}>{children}</ModalContext.Provider>
}

// Hook to use Modal Context
export const useModalContext = () => {
	const context = useContext(ModalContext)
	if (!context) {
		throw new Error('useModalContext must be used within a ModalProvider')
	}
	return context
}
