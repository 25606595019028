import { Assessment } from './generated/graphql.ts'

export const isAssessmentFilledOutByUser = (assessment: Assessment) => {
	return !!assessment.userResponse?.completedAt
}

export const isAssessmentFilledOutByManager = (assessment: Assessment) => {
	return !!assessment.managerResponse?.completedAt
}

export const transformPositionsArray = (inputArray) => {
	// Step 1: Create a map to accumulate levels for each title
	const titleMap = new Map()

	// Step 2: Populate the map with levels for each title
	inputArray.forEach(({ level, role }) => {
		if (!titleMap.has(role)) {
			titleMap.set(role, [])
		}
		titleMap.get(role).push(level)
	})

	// Step 3: Convert the map into the desired output format
	return Array.from(titleMap, ([role, levels]) => ({
		role,
		levels,
	}))
}
