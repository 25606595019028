import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'

export interface IOrganizationInvite {
	id: string
	email: string
	inviteStatus: string
	logtoInviteId: string
	organizationId: string
	inviteSenderId: string
	managerId: string
	positionId: string
	positionTrajectoryId: string
	logTo: {
		tenantId: string
		id: string
		inviterId: string
		invitee: string
		acceptedUserId: string
		organizationId: string
		status: string
		createdAt: number
		updatedAt: number
		expiresAt: number
		organizationRoles: {
			id: string
			name: string
		}[]
	}
}

export const getOrganizationInvites = async (client: HttpClient, organizationId: string): Promise<IOrganizationInvite[]> => {
	const response = await client.get<IOrganizationInvite[]>(`/api/organizations/${organizationId}/invites`)

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
