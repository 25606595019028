import { IdTokenClaims } from '@logto/react'
import { createContext, useContext } from 'react'

interface Auth {
	token?: string
	authUser?: IdTokenClaims
	refreshAuth?: () => Promise<void>
}

const AuthContext = createContext<Auth>({})

const useAuth = (): Auth => {
	return useContext(AuthContext)
}

export { AuthContext, useAuth }
