import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'

export interface TrajectoryPosition {
	id: string
	title: string
	level: string
	role: string
	description: string
	organizationId: never
}

export interface ITrajectory {
	id: string
	fromPositionId: string
	toPositionId?: string | null
	progressionOrder: number
	fromPosition: TrajectoryPosition
	toPosition?: TrajectoryPosition | null
	path: string
}

export const getFullTrajectory = async (client: HttpClient, path: string): Promise<ITrajectory[]> => {
	const response = await client.get<ITrajectory[]>(`/api/trajectory/${path}`)

	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])

	return response.data
}
