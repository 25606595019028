import React from 'react'
import DefaultLayout from '../../layout/DefaultLayout.tsx'
import { useGraphQLClient } from '../../useGraphQLClient.ts'
import {
	AssessmentsWithinUserPositionQuery,
	AssessmentWithResponsesQuery,
	useAssessmentsWithinUserPositionQuery,
	useAssessmentWithResponsesQuery,
} from '../../generated/graphql.ts'
import { RadarComponent } from '../../components/Charts/Radar.tsx'
import PeriodAssessmentsChart from '../../components/Charts/PeriodAssessmentsChart.tsx'
import Loader from '../../common/Loader'
import { getMe } from '../../MeProvider.ts'
import OverviewCard from './OverviewCard.tsx'
import { formatTimestamp, generateChartData, snakeToWords, toSnakeCase } from './utils.ts'
import dayjs from 'dayjs'
import { useGetFullTrajectory } from '../../http/hooks.ts'
import { useRestClient } from '../../useRestClient.ts'
import { markCompleted } from '../Employees/Detail.tsx'
import { ITrajectory } from '../../services/nextpeakClient'

const PositionAssessmentDisplay = ({
	assessments,
}: {
	assessments: AssessmentsWithinUserPositionQuery['assessmentsWithinUserPosition']
}) => {
	const mappedData = assessments?.reduce(
		(acc, assessment) => {
			if (assessment) {
				const timestamp = dayjs(assessment.managerResponse!.completedAt).valueOf()
				acc.manager.push([timestamp, assessment.managerResponse!.averageScore!])
				acc.employee.push([timestamp, assessment.userResponse!.averageScore!])
			}

			return acc
		},
		{
			manager: [] as number[][],
			employee: [] as number[][],
		}
	)

	return (
		<PeriodAssessmentsChart
			series={[
				{ name: 'Manager eval %', data: mappedData!.manager },
				{
					name: 'Personal eval %',
					data: mappedData!.employee,
				},
			]}
		/>
	)
}

export const PositionAssessments = ({
	data,
	isLoading,
}: {
	data?: AssessmentsWithinUserPositionQuery['assessmentsWithinUserPosition']
	isLoading: boolean
}) => {
	const dataExists = Boolean(data?.length)

	return (
		<div className="col-span-12 rounded-2xl bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
			<div className="mb-4 justify-between gap-4 sm:flex">
				<div>
					<h4 className="text-xl font-semibold text-black dark:text-white">Position progress</h4>
				</div>
			</div>
			<div style={{ minHeight: '414px' }} className={!dataExists ? 'flex justify-center items-center' : ''}>
				{isLoading && <Loader />}
				{!isLoading && dataExists && <PositionAssessmentDisplay assessments={data} />}
				{!isLoading && !dataExists && <p>No data yet</p>}
			</div>
		</div>
	)
}

const LatestAssessmentGraph = ({ data }: { data: AssessmentWithResponsesQuery['assessment'] }) => {
	const chartData = generateChartData(data)

	return <RadarComponent chartData={chartData!} />
}

export const LatestAssesment = ({
	data: allAssessments,
}: {
	data?: AssessmentsWithinUserPositionQuery['assessmentsWithinUserPosition']
	isLoading: boolean
}) => {
	const graphQlClient = useGraphQLClient()

	const { data, isLoading } = useAssessmentWithResponsesQuery(
		graphQlClient,
		{ assessmentId: allAssessments?.[0]?.id! },
		{
			enabled: !!allAssessments?.[0]?.id,
		}
	)

	return (
		<div className="rounded-2xl bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark col-span-12 xl:col-span-4">
			<div className="mb-4 justify-between gap-4 sm:flex">
				<div>
					<h4 className="text-xl font-semibold text-black dark:text-white">Current evaluation</h4>
				</div>
			</div>
			<div style={{ minHeight: '404px' }} className="flex justify-center items-center">
				{isLoading && <Loader />}
				{!isLoading && data && <LatestAssessmentGraph data={data.assessment} />}
				{!isLoading && !data && <p>No data</p>}
			</div>
		</div>
	)
	// return <RadarComponent chartData={chartData}/>
}

const Trajectory = ({ employee, trajectoryItems }: { employee: any; trajectoryItems: ITrajectory[] }) => {
	const markedItems = markCompleted(trajectoryItems, employee.userPosition!.position.id)

	console.log(markedItems)

	return (
		<div className="flex gap-3 py-3">
			{markedItems.map((trajectory) => (
				<div
					key={trajectory.id}
					className={`inline-block flex-grow text-center font-bold rounded-md ${trajectory.isCompleted ? 'bg-meta-3 text-white' : ''}`}
				>
					{trajectory.fromPosition.level}
				</div>
			))}
		</div>
	)
}

const HomePage: React.FC = () => {
	const me = getMe()
	const graphQlClient = useGraphQLClient()
	const restClient = useRestClient()
	const { data, isLoading } = useAssessmentsWithinUserPositionQuery(graphQlClient, { latest: false, isCompleted: true })
	const { data: trajectory, isLoading: isLoadingTrajectory } = useGetFullTrajectory(
		restClient,
		toSnakeCase(me.userPosition!.position.role) + '_path'
	)

	return (
		<DefaultLayout>
			<div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
				{
					<OverviewCard title="Current position level" total={me?.userPosition?.position.level ?? 'Unknown level'}>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3C50E0" className="size-6">
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"
							/>
						</svg>
					</OverviewCard>
				}
				<div className="col-span-2 rounded-2xl bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
					{isLoadingTrajectory && <Loader />}
					{!isLoadingTrajectory && trajectory && <Trajectory employee={me} trajectoryItems={trajectory} />}

					<div className="mt-4 flex items-end justify-between">
						<div>
							<h4 className="text-title-md font-bold text-black dark:text-white">
								{me?.userPosition?.positionTrajectory.path ? snakeToWords(me.userPosition.positionTrajectory.path) : 'Unknown path'}
							</h4>
							<span className="text-sm font-medium">Current career path</span>
						</div>
					</div>
				</div>
				<OverviewCard
					title="At position since"
					total={me.userPosition?.sinceDate ? formatTimestamp(me.userPosition.sinceDate, 'DD MMM YYYY') : ''}
				>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3C50E0" className="size-6">
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
						/>
					</svg>
				</OverviewCard>
			</div>
			<div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
				<PositionAssessments data={data} isLoading={isLoading} />
				<LatestAssesment data={data} isLoading={isLoading} />
			</div>
		</DefaultLayout>
	)
}

export default HomePage
