import { Assessment } from '../../generated/graphql.ts'

export const getAssessmentResponseByUserId = (assessment: Assessment, userId: string) => {
	if (assessment.managerResponse?.user.id === userId) {
		return {
			...assessment.managerResponse,
			type: 'manager',
		}
	}
	if (assessment.userResponse?.user.id === userId) {
		return {
			...assessment.userResponse,
			type: 'employee',
		}
	}

	return null
}

export const mergeResponses = (employeeResponse: any, managerResponse: any) => {
	const result: any = {
		employeeAverageScore: employeeResponse.averageScore,
		managerAverageScore: managerResponse.averageScore,
		averageByCategory: [],
	}

	for (let i = 0; i < employeeResponse.averageByCategory.length; i++) {
		const employeeCategory = employeeResponse.averageByCategory[i]
		const matchingManagerCategory = managerResponse.averageByCategory.find(
			(managerCategory: any) => managerCategory.category === employeeCategory.category
		)

		const category: any = {
			category: employeeCategory.category,
			employeeAverageScore: employeeCategory.average,
			managerAverageScore: matchingManagerCategory?.average,
			subcategories: [],
		}

		for (let j = 0; j < employeeCategory.subcategories.length; j++) {
			const employeeSubcategory = employeeCategory.subcategories[j]
			const matchingManagerSubcategory = matchingManagerCategory?.subcategories.find(
				(managerSubcategory: any) => managerSubcategory.category === employeeSubcategory.category
			)

			const subcategory: any = {
				category: employeeSubcategory.category,
				employeeAverageScore: employeeSubcategory.average,
				managerAverageScore: matchingManagerSubcategory?.average,
				answers: [],
			}

			for (let k = 0; k < employeeSubcategory.answers.length; k++) {
				const employeeAnswer = employeeSubcategory.answers[k]
				const matchingManagerAnswer = matchingManagerSubcategory?.answers.find(
					(managerAnswer: any) => managerAnswer.question.id === employeeAnswer.question.id
				)

				const answer = {
					employeeAnswerValue: employeeAnswer.answerValue,
					managerAnswerValue: matchingManagerAnswer?.answerValue,
					question: employeeAnswer.question,
				}

				subcategory.answers.push(answer)
			}

			category.subcategories.push(subcategory)
		}

		result.averageByCategory.push(category)
	}

	return result
}
