import { HttpClient } from '../../httpClient'
import { throwIfNotAllowedContentType, validateHttpResponse } from '../../httpClient/utils.ts'

export const updateUserManager = async (client: HttpClient, userId: string, managerId: string) => {
	const response = await client.post(`/api/users/${userId}/changeManager`, {
		managerId,
	})
	await validateHttpResponse(response)
	throwIfNotAllowedContentType(response, ['application/json'])
	return response.data
}
