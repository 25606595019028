import { useModalContext } from './modalContext.tsx'

export const useModalsList = () => {
	const { state } = useModalContext()
	return Object.keys(state.modal).filter((modalId) => state.modal[modalId].open)
}

export const useIsModalOpen = (modalFileName: string) => {
	const { state } = useModalContext()
	return state.modal[modalFileName]?.open ?? false
}

export const useModalMeta = (modalFileName: string) => {
	const { state } = useModalContext()
	return state.modal[modalFileName]?.meta
}
